.chat-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-section canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chat-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-height: 90vh;
}

.chat-window {
  flex: 0 1 70vh;
  width: 100%;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.chat-message {
  display: flex;
  margin-bottom: 15px;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-message.user {
  justify-content: flex-end;
}

.chat-message.assistant {
  justify-content: flex-start;
}

.chat-bubble {
  max-width: 75%;
  padding: 12px 18px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap; /* Added to preserve whitespace and line breaks */
}

.chat-bubble pre {
  margin: 0; /* Remove default margin from pre tag */
  font-family: inherit; /* Ensure consistent font */
  white-space: pre-wrap; /* Ensure pre tag wraps text */
}

.chat-message.user .chat-bubble {
  background-color: rgba(0, 123, 255, 0.7);
  color: #fff;
  border-bottom-right-radius: 0;
}

.chat-message.assistant .chat-bubble {
  background-color: rgba(173, 216, 230, 0.7);
  color: #333;
  border-bottom-left-radius: 0;
}

.chat-input {
  width: 100%;
  max-width: 800px;
  display: flex;
  padding-bottom: 20px;
}

.chat-input input {
  flex: 1;
  padding: 15px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 30px;
  margin-right: 10px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
}

.chat-input input:focus {
  border-color: #0056b3;
}

.chat-input button {
  padding: 0 25px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.chat-input button:hover:not(:disabled) {
  background-color: #0056b3;
}

.chat-input button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.loading span {
  font-size: 24px;
  animation: blink 1.4s infinite;
  margin: 0 2px;
}

.loading .dot-one {
  animation-delay: 0s;
}
.loading .dot-two {
  animation-delay: 0.2s;
}
.loading .dot-three {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .chat-content {
    max-height: 95vh;
  }

  .chat-window {
    flex: 0 1 60vh;
  }

  .chat-window,
  .chat-input {
    width: 100%;
  }

  .chat-bubble {
    max-width: 85%;
  }
}
