@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  font-family: "Poppins", sans-serif;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* Add some basic styles for Header and Footer if they're not already styled */
.header,
.footer {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 20px;
}

.hero-section {
  /* background: url('./components/banner_main_3.png'); */
  /* background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat; */
  color: white;
  padding: 60px 20px;
  text-align: center;
}

.banner-title-primary {
  background: #fe6f6169;
  padding: 0 10px;
}

.content-wrapper {
  position: relative;
  width: 100%;
  /* No height specified, so it will expand to fit content */
}

.blur-bg {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.card-text {
  font-size: 0.9rem;
  font-weight: 400;
}