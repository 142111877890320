.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 20px;
}

.hero-content h1 {
  font-size: 3.2em;
  margin-bottom: 20px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.hero-metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.metric {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  backdrop-filter: blur(5px);
}

.metric-value {
  font-size: 2.5em;
  font-weight: bold;
  color: #ff6f61;
}

.metric-label {
  font-size: 0.9em;
  opacity: 0.8;
}

.cta-button {
  background-color: #ff6f61;
  padding: 15px 30px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #ff3b2f;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5em;
  }

  .hero-metrics {
    flex-direction: column;
    align-items: center;
  }

  .metric {
    margin-bottom: 15px;
  }
}
